@import "../../styles/base.scss";

.component-payload-viewer {
	position: absolute;
	width: 100%;
	height: 100%;
	overflow: scroll;


	.payloads {

	}

	.payload {
		pre {
			overflow-x: scroll;
			margin: 0;
		}
	}
}
