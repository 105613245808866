@import "../../styles/base.scss";

.component-turbine-power-chart {
	width: 100%;
	height: 100%;

	h2 {
		font-size: 13px;
		color: $color-title-blue;
		font-weight: bolder;
	}
	.real-time-value {
		display: block;
		font-size: 28px;
		font-weight: bold;
		padding-bottom: 10px;
	}
}