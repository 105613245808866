@import "../../styles/base.scss";

.component-payload-viewer,
.component-device-viewer {

	.payloads,
	.devices {
		list-style-type: none;
		margin: 0;
		padding: 0;
	}

	.payload,
	.device {
		background-color: $color-white;
		margin: 10px;
		box-shadow: $standard-box-shadow;

		section,
		pre {
			padding: 10px;
		}

		dl, dt, dd {
			margin: 0 0 5px;
			color: $color-dark-gray;
		}

		dt {
			font-style: italic;
		}
		dd {
			max-width: 250px;
			white-space: nowrap;
			text-overflow: ellipsis;
			word-break: break-all;
			overflow: hidden;
			font-size: $font-size-small;
		}

		.icon {
			width: 32px;
			height: 32px;
			background-color: $color-green;
			float: left;
			margin-right: 10px;
			font-size: $font-size-medium;
			background-image: url('./pi.png');
			background-repeat: no-repeat;
			background-position: center;
			border-radius: 2px;
		}
		header {
			padding: 10px;
		}

		footer {
			border-top: $standard-border;
			padding: 10px;
			text-transform: uppercase;
			font-size: 14px;

			.icon {
				display: block;
				width: 16px;
				height: 16px;
				margin: 3px;
			}
		}
	}
}
