
$color-white: #ffffff;
$color-off-white: #F6F7F7;

$color-dark-green: #283E40;
$color-light-green: #DCEDE9;
$color-green: #1E856D;

$color-dark-gray: #777;
$color-medium-gray: #E4E6E7;

$color-blue: #64ACDE;
$color-darker-blue: #0E7DCC;


$header-height: 60px;
$standard-border: solid 1px #BEC5C5;

$standard-box-shadow: 1px 1px 3px #ccc;

$font-size-extra-large: 56px;
$font-size-large: 16pt;
$font-size-medium: 12pt;
$font-size-small: 10pt;

// TV Dashboard colors
$color-dark-green-bg: rgb(1, 48, 57);
$color-darker-green-bg: rgb(0, 38, 44);
$color-title-blue: rgb(95, 148, 224);

// global styles really shouldn't go here... :P
* {
	box-sizing: border-box;
}

// global links
a,
a:visited {
	color: $color-green;
	text-decoration: none;
}
a:hover {
	color: $color-dark-green;
}
