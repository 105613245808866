@import "../../styles/base.scss";

.component-dashboard-tv-view {
	position: relative;
	height: 100%;
	width: 100%;
	background-color: $color-dark-green-bg;
	color: $color-white;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;

	.loading-content {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		background-color: $color-darker-green-bg;
		z-index: 1000;

		display: flex;
		flex-direction: column;
		text-align: center;
		justify-content: center;
	}

	h1 {
		flex: 0 1 auto;
		height: 50px;
		margin: 0;
		padding: 15px 20px 0;
		font-weight: lighter;
	}

	.tv-content {
		flex: 1 1 auto;
		height: 75%;
		position: relative;
		display: flex;
		padding: 20px;
	}

	.tv-column {
		flex: 0 1 auto;
		width: 50%;
		display: flex;
		flex-direction: column;
	}

	.tv-row {
		margin: 10px 0;
		padding: 10px;
		flex: 1 1 auto;
		height: 10%;
		background-color: $color-darker-green-bg;
		box-shadow: 5px 5px 10px rgba(0, 0, 0, .3);

		&.single-value-items {
			flex: 0 0 auto;
			height: 80px;
			background-color: transparent;
			box-shadow: none;
			display: flex;
			flex-direction: row;
			padding: 0;

			.single-value-item {
				flex: 0 1 auto;
				width: 50%;
				height: 100%;
				padding: 10px;
				&:not(:last-child) {
					margin-right: 20px;
				}
				background-color: $color-darker-green-bg;
				box-shadow: 5px 5px 10px rgba(0, 0, 0, .3);

				// fonts
				text-align: center;
				h2 {
					margin-top: 10px;
				}
			}
		}
	}

	.mini-map {
		padding: 0;
		height: 40%;
	}

	.production-graph {
		height: 30%;
	}
}

.component-dashboard-tv-view {
	$animation-length-in: .3s;
	$animation-length-out: .5s;

	// CSSTransition classes
	.state-animate-enter {
		animation-duration: $animation-length-in;
		opacity: 0;
	}

	.state-animate-enter-active {;
		animation-name: fadein;
	}

	.state-animate-enter-done {
		opacity: 1;
	}

	.state-animate-exit {
		animation-duration: $animation-length-out;
		opacity: 1;
	}

	.state-animate-exit-active {
		animation-name: fadeout;
	}

	.state-animate-exit-done {
		opacity: 0;
		display: none;
	}
}

.animate-pulsate {
	-webkit-animation: pulsate 2s ease-out;
	-webkit-animation-iteration-count: infinite;
	opacity: 0.5;
}

@-webkit-keyframes pulsate {
	0% {
		opacity: 0.5;
	}
	50% {
		opacity: 1.0;
	}
	100% {
		opacity: 0.5;
	}
}

@keyframes fadeout {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

@keyframes fadein {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
