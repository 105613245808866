@import "../../styles/base.scss";

ul {
	padding-inline-start: 0;
}
.left-nav {
	height: 100%;
	background-color: $color-medium-gray;
	color: $color-green;
	overflow-y: auto;

	a {
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}
	}

	ul {
		list-style: none;
	}


	h1 {
		background-color: $color-dark-green;
		color: $color-light-green;
		margin: 0;
		padding: 0 10px;
		line-height: $header-height;
		text-align: center;
		font-size: 36px;
		font-weight: 300;
	}

	.main {
		padding: 5px 15px;

		:first-child .nav-level-0 {
			font-size: 18px;
			color: $color-green;
			font-weight: normal;
			cursor: pointer;
			
			&:hover {
				text-decoration: underline;
			}
		}
	}

	.nav-level-0{
		font-weight: bold;
		font-size: 13px;
		color: #161B1C;
		cursor: default;
		&:hover {
			text-decoration: none;
		}

	}

	.nav-level-1{
		font-weight: 300;
		font-size: 18px;
		color: #161B1C;
		cursor: default;
		&:hover {
			text-decoration: none;
		}
	}

	.nav-header li a {
		font-weight: normal;
	}

	li ul {
		margin-top: -16px;
	}

	li ul ul {
		margin: 0;
	}
}

.left-nav > ul > li {
	font-weight: normal;
	line-height: 40px;
	font-size: 18px;
	&:before {
		display:inline-block;
		background-image: url('./map.png');
	}

}

.left-nav > ul > li li li {
	font-size: 18px;
	font-weight: normal;
	margin-left: 20px;
}
