@import "./styles/base.scss";

body {
	//background-color: #dfdfdf;
}

html {
	overflow: hidden;
}

html, body {
	height: 100%;
	margin: 0;
	padding: 0;
	min-width: 800px;
}

body,
h1, h2, h3, h4, h5, h6 {
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: 400;
}

#root {
	height: 100%;
}

.app-component {
	height: 100%;
	position: relative;
	width: 100%;
	display: flex;
	background-color: $color-off-white;

	& > .left {
		flex: 0 0 200px;
	}

	& > .right {
		flex: 1 1 100%;
		position: relative;
		overflow: hidden;

		.component-app-child {
			background-color: $color-off-white;
			position: absolute;
			top: 0;
		}

		// CSSTransition classes
		.state-animate-enter {
			left: 100%;
			animation-duration: .5s;
			animation-timing-function: cubic-bezier(.31,.54,.21,1); //cubic-bezier(.52,1.4,.77,.96);
		}

		.state-animate-enter-active {;
			animation-name: slidein;
			left: 0%;
		}

		.state-animate-enter-done {
			left: 0%;
		}

		.state-animate-exit {
			left: 0%;
			animation-duration: .3s;

			&.fade {
				animation-name: fadeout;
				opacity: 1;
			}
		}

		.state-animate-exit-active {
			animation-name: slideout;
			animation-timing-function: ease-out;
			left: 100%;

			&.fade {
				animation-name: fadeout;
				opacity: 0;
				left: 0%;
			}
		}

		.state-animate-exit-done {
			left: 100%;

			&.fade {
				animation-name: fadeout;
				opacity: 0;
				left: 0%;
			}
		}


	}
}


@keyframes fadeout {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

@keyframes slidein {
	from {
		left: 100%;
	}

	to {
		left: 0%;
	}
}


@keyframes slideout {
	from {
		left: 0%;
	}

	to {
		left: 100%;
	}
}

.app-component-header {
	min-height: $header-height;
	background-color: $color-light-green;
	position: relative;

	h1, h2 {
		color: $color-dark-green;
		margin: 0;
		margin-left: 15px;
		padding: 0;
		line-height: $header-height;
		font-weight: 300;
	}

	button {
		border: $standard-border;
		background-color: $color-medium-gray;
		padding: 5px;
		top: 50%;
		margin-top: -15px;
		right: 5px;
		position: absolute;
	}
}
