@import "../../styles/base.scss";

.component-map-view {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;

	.map-area {
		flex: 1 1 auto;
		height: calc(100vh - 60px);
	}
}
