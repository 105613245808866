body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box; }

a,
a:visited {
  color: #1E856D;
  text-decoration: none; }

a:hover {
  color: #283E40; }

html {
  overflow: hidden; }

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  min-width: 800px; }

body,
h1, h2, h3, h4, h5, h6 {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 400; }

#root {
  height: 100%; }

.app-component {
  height: 100%;
  position: relative;
  width: 100%;
  display: flex;
  background-color: #F6F7F7; }
  .app-component > .left {
    flex: 0 0 200px; }
  .app-component > .right {
    flex: 1 1 100%;
    position: relative;
    overflow: hidden; }
    .app-component > .right .component-app-child {
      background-color: #F6F7F7;
      position: absolute;
      top: 0; }
    .app-component > .right .state-animate-enter {
      left: 100%;
      -webkit-animation-duration: .5s;
              animation-duration: .5s;
      -webkit-animation-timing-function: cubic-bezier(0.31, 0.54, 0.21, 1);
              animation-timing-function: cubic-bezier(0.31, 0.54, 0.21, 1); }
    .app-component > .right .state-animate-enter-active {
      -webkit-animation-name: slidein;
              animation-name: slidein;
      left: 0%; }
    .app-component > .right .state-animate-enter-done {
      left: 0%; }
    .app-component > .right .state-animate-exit {
      left: 0%;
      -webkit-animation-duration: .3s;
              animation-duration: .3s; }
      .app-component > .right .state-animate-exit.fade {
        -webkit-animation-name: fadeout;
                animation-name: fadeout;
        opacity: 1; }
    .app-component > .right .state-animate-exit-active {
      -webkit-animation-name: slideout;
              animation-name: slideout;
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      left: 100%; }
      .app-component > .right .state-animate-exit-active.fade {
        -webkit-animation-name: fadeout;
                animation-name: fadeout;
        opacity: 0;
        left: 0%; }
    .app-component > .right .state-animate-exit-done {
      left: 100%; }
      .app-component > .right .state-animate-exit-done.fade {
        -webkit-animation-name: fadeout;
                animation-name: fadeout;
        opacity: 0;
        left: 0%; }

@-webkit-keyframes fadeout {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeout {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes slidein {
  from {
    left: 100%; }
  to {
    left: 0%; } }

@keyframes slidein {
  from {
    left: 100%; }
  to {
    left: 0%; } }

@-webkit-keyframes slideout {
  from {
    left: 0%; }
  to {
    left: 100%; } }

@keyframes slideout {
  from {
    left: 0%; }
  to {
    left: 100%; } }

.app-component-header {
  min-height: 60px;
  background-color: #DCEDE9;
  position: relative; }
  .app-component-header h1, .app-component-header h2 {
    color: #283E40;
    margin: 0;
    margin-left: 15px;
    padding: 0;
    line-height: 60px;
    font-weight: 300; }
  .app-component-header button {
    border: solid 1px #BEC5C5;
    background-color: #E4E6E7;
    padding: 5px;
    top: 50%;
    margin-top: -15px;
    right: 5px;
    position: absolute; }

* {
  box-sizing: border-box; }

a,
a:visited {
  color: #1E856D;
  text-decoration: none; }

a:hover {
  color: #283E40; }

.component-map-view {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; }
  .component-map-view .map-area {
    flex: 1 1 auto;
    height: calc(100vh - 60px); }

.component-apple-map {
  width: 100%;
  height: 100%; }

.landmark {
  width: 250px;
  padding: 0;
  background: rgba(247, 247, 247, 0.85);
  border-radius: 5px;
  box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.29);
  -webkit-transform-origin: 0 10px;
  transform-origin: 0 10px; }
  .landmark .equipment-image {
    width: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px; }
  .landmark .user-image {
    width: 26px;
    height: 26px;
    border-radius: 13px;
    margin-right: 10px;
    object-fit: cover; }
  .landmark .link a {
    text-align: right;
    color: #0E7DCC; }
    .landmark .link a:visited {
      color: #0E7DCC; }
  .landmark h1 {
    position: relative;
    margin-top: -34px;
    padding: 5px 15px;
    background: rgba(0, 0, 0, 0.3);
    color: rgba(255, 255, 255, 0.9);
    font-size: 16px;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    whte-space: nowrap; }
  .landmark .lat-lon {
    font-style: italic; }

.landmark h5 {
  margin-bottom: 0;
  color: #333333;
  font-size: 14px;
  font-weight: 500; }

.landmark section {
  padding: 0 15px 5px;
  font-size: 14px; }

.landmark section p {
  margin: 5px 0; }

.landmark:after {
  content: "";
  position: absolute;
  top: 34px;
  left: -13px;
  width: 0;
  height: 0;
  margin-bottom: -13px;
  border-right: 13px solid rgba(247, 247, 247, 0.85);
  border-top: 13px solid rgba(0, 0, 0, 0);
  border-bottom: 13px solid rgba(0, 0, 0, 0); }

@-webkit-keyframes scale-and-fadein {
  0% {
    -webkit-transform: scale(0.2);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
    opacity: 1; } }

@keyframes scale-and-fadein {
  0% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
    opacity: 0; }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1; } }

.component-live-chart.chart-content {
  height: 100%;
  display: flex;
  flex-direction: column; }
  .component-live-chart.chart-content h2, .component-live-chart.chart-content span {
    max-height: 25%;
    flex: 0 1 auto; }
  .component-live-chart.chart-content .chart-content {
    flex: 1 1 auto;
    max-height: 80%;
    position: relative;
    overflow: hidden; }

* {
  box-sizing: border-box; }

a,
a:visited {
  color: #1E856D;
  text-decoration: none; }

a:hover {
  color: #283E40; }

.component-payload-viewer {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: scroll; }
  .component-payload-viewer .payload pre {
    overflow-x: scroll;
    margin: 0; }

* {
  box-sizing: border-box; }

a,
a:visited {
  color: #1E856D;
  text-decoration: none; }

a:hover {
  color: #283E40; }

.component-payload-viewer .payloads,
.component-payload-viewer .devices,
.component-device-viewer .payloads,
.component-device-viewer .devices {
  list-style-type: none;
  margin: 0;
  padding: 0; }

.component-payload-viewer .payload,
.component-payload-viewer .device,
.component-device-viewer .payload,
.component-device-viewer .device {
  background-color: #ffffff;
  margin: 10px;
  box-shadow: 1px 1px 3px #ccc; }
  .component-payload-viewer .payload section,
  .component-payload-viewer .payload pre,
  .component-payload-viewer .device section,
  .component-payload-viewer .device pre,
  .component-device-viewer .payload section,
  .component-device-viewer .payload pre,
  .component-device-viewer .device section,
  .component-device-viewer .device pre {
    padding: 10px; }
  .component-payload-viewer .payload dl, .component-payload-viewer .payload dt, .component-payload-viewer .payload dd,
  .component-payload-viewer .device dl,
  .component-payload-viewer .device dt,
  .component-payload-viewer .device dd,
  .component-device-viewer .payload dl,
  .component-device-viewer .payload dt,
  .component-device-viewer .payload dd,
  .component-device-viewer .device dl,
  .component-device-viewer .device dt,
  .component-device-viewer .device dd {
    margin: 0 0 5px;
    color: #777; }
  .component-payload-viewer .payload dt,
  .component-payload-viewer .device dt,
  .component-device-viewer .payload dt,
  .component-device-viewer .device dt {
    font-style: italic; }
  .component-payload-viewer .payload dd,
  .component-payload-viewer .device dd,
  .component-device-viewer .payload dd,
  .component-device-viewer .device dd {
    max-width: 250px;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
    overflow: hidden;
    font-size: 10pt; }
  .component-payload-viewer .payload .icon,
  .component-payload-viewer .device .icon,
  .component-device-viewer .payload .icon,
  .component-device-viewer .device .icon {
    width: 32px;
    height: 32px;
    background-color: #1E856D;
    float: left;
    margin-right: 10px;
    font-size: 12pt;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAABGdBTUEAALGPC/xhBQAAAUdJREFUOBGNkzFLA0EQhW9NQEErMY1p7bUVsRHsLCxsFFtLGwsL/QWiaKNWNmJn4S+wsfIfWImgrQTEWIQQsn7vbjdMLrdwA29n5s3b2Z1bLstqmPf+CXSilPgMHMfc+iaFE4g94GwhxJ/4D7AJ5tA+4GfBNuiTL+N/gOzdOXeVQf6CTgI6TBptlq2Cgzwqlg3VZaQXwDWJ7+h8lLP1lj6ybpAOzBZPnDc0XDLcpzIN3jj8FX+fUuqGuXHdHuKZmFsP/2hzG7PvkHwRrINTzX4pAV5XHjOogXjMjlbWfBWSYp0aq04mjUBFP6koMaORS3xMhwQ6VD5ltxTaYA0825F7qR11eAY+B43RyKkHqdPMatRwi847lrQxtRfwHZDUxT1quARWIlHh9X0WAuYr6pbyepRr0OIGN7Zi4paJd9Hp/62yPz7b8B+mLMG3jsB53QAAAABJRU5ErkJggg==);
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 2px; }
  .component-payload-viewer .payload header,
  .component-payload-viewer .device header,
  .component-device-viewer .payload header,
  .component-device-viewer .device header {
    padding: 10px; }
  .component-payload-viewer .payload footer,
  .component-payload-viewer .device footer,
  .component-device-viewer .payload footer,
  .component-device-viewer .device footer {
    border-top: solid 1px #BEC5C5;
    padding: 10px;
    text-transform: uppercase;
    font-size: 14px; }
    .component-payload-viewer .payload footer .icon,
    .component-payload-viewer .device footer .icon,
    .component-device-viewer .payload footer .icon,
    .component-device-viewer .device footer .icon {
      display: block;
      width: 16px;
      height: 16px;
      margin: 3px; }

* {
  box-sizing: border-box; }

a,
a:visited {
  color: #1E856D;
  text-decoration: none; }

a:hover {
  color: #283E40; }

.detail-view {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row; }
  .detail-view .image {
    width: 200px;
    height: 200px;
    margin: 0 15px 15px 0;
    float: left;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top center; }
    .detail-view .image .thumbnail {
      width: 100%;
      height: 100%; }
  .detail-view .maintenance-button {
    float: left;
    margin-top: 15px;
    width: 220px;
    background-color: #C83C36;
    border: none;
    border-radius: 19px;
    height: 38px;
    font-size: 16px;
    color: white; }
    .detail-view .maintenance-button a {
      display: block;
      color: #ffffff;
      text-align: center;
      line-height: 38px; }
  .detail-view .record-fields {
    float: left;
    margin: 0;
    font-size: 16px;
    margin-right: 15px; }
    .detail-view .record-fields dt {
      float: left;
      clear: left;
      width: 100px; }
    .detail-view .record-fields dd {
      width: 180px;
      text-align: right; }
    .detail-view .record-fields .list-link {
      margin-left: 0;
      text-align: left; }
    .detail-view .record-fields .record-title {
      font-size: 24px;
      font-weight: 300;
      width: 100%; }
  .detail-view .detail-view-left {
    flex: 1 1 50%;
    min-width: 450px;
    overflow-y: scroll; }
  .detail-view .detail-view-right {
    flex: 0 1 300px;
    background-color: #F6F7F7;
    border-left: solid 1px #BEC5C5;
    display: flex;
    flex-direction: column; }
  .detail-view .detail-view-right-content {
    position: relative;
    flex: 1 1 auto; }
  .detail-view header {
    flex: 0 0 40px; }
  .detail-view .detail-data {
    display: flex;
    padding: 25px;
    border-bottom: solid 1px #BEC5C5; }
    .detail-view .detail-data .detail-data-left {
      flex: 1 1 auto;
      min-width: 200px; }
    .detail-view .detail-data .detail-data-right {
      flex: 0 1 80%;
      display: flex;
      flex-wrap: wrap; }
    .detail-view .detail-data .data-group {
      flex: 0 1 50%;
      width: 200px;
      height: 100px; }
      .detail-view .detail-data .data-group label {
        font-size: 12px;
        color: #0E7DCC;
        font-weight: bold; }
      .detail-view .detail-data .data-group .chart {
        width: 200px;
        height: 75px;
        margin-top: 5px;
        background-color: #64ACDE;
        border: 1px solid #0E7DCC;
        border-radius: 3px;
        color: white;
        text-align: center;
        font-size: 44px;
        padding-top: 8px;
        font-weight: bold;
        text-shadow: rgba(0, 0, 0, 0.2) 1px 1px 4px; }
        .detail-view .detail-data .data-group .chart span {
          font-size: 18px; }
  .detail-view .detail-charts .detail-charts-group {
    display: flex; }
    .detail-view .detail-charts .detail-charts-group h2 {
      font-size: 10pt;
      font-weight: bold;
      margin: 0;
      padding: 0; }
    .detail-view .detail-charts .detail-charts-group .real-time-value {
      font-size: 56px;
      font-weight: bold; }
    .detail-view .detail-charts .detail-charts-group .component-live-chart {
      flex: 0 1 auto;
      width: 50%;
      padding: 25px; }
      .detail-view .detail-charts .detail-charts-group .component-live-chart.theme-green {
        color: #357C27; }
      .detail-view .detail-charts .detail-charts-group .component-live-chart.theme-orange {
        color: #DBA756; }
  .detail-view .maintenance-history {
    padding: 25px; }
    .detail-view .maintenance-history h2 {
      font-size: 12pt;
      margin: 0 0 25px;
      padding: 0; }
    .detail-view .maintenance-history a {
      display: block;
      text-decoration: none;
      width: 80%;
      height: 200px;
      background: url(/static/media/history.243f78ae.jpg) no-repeat top center;
      background-size: 100% auto; }

* {
  box-sizing: border-box; }

a,
a:visited {
  color: #1E856D;
  text-decoration: none; }

a:hover {
  color: #283E40; }

ul {
  -webkit-padding-start: 0;
          padding-inline-start: 0; }

.left-nav {
  height: 100%;
  background-color: #E4E6E7;
  color: #1E856D;
  overflow-y: auto; }
  .left-nav a {
    cursor: pointer; }
    .left-nav a:hover {
      text-decoration: underline; }
  .left-nav ul {
    list-style: none; }
  .left-nav h1 {
    background-color: #283E40;
    color: #DCEDE9;
    margin: 0;
    padding: 0 10px;
    line-height: 60px;
    text-align: center;
    font-size: 36px;
    font-weight: 300; }
  .left-nav .main {
    padding: 5px 15px; }
    .left-nav .main :first-child .nav-level-0 {
      font-size: 18px;
      color: #1E856D;
      font-weight: normal;
      cursor: pointer; }
      .left-nav .main :first-child .nav-level-0:hover {
        text-decoration: underline; }
  .left-nav .nav-level-0 {
    font-weight: bold;
    font-size: 13px;
    color: #161B1C;
    cursor: default; }
    .left-nav .nav-level-0:hover {
      text-decoration: none; }
  .left-nav .nav-level-1 {
    font-weight: 300;
    font-size: 18px;
    color: #161B1C;
    cursor: default; }
    .left-nav .nav-level-1:hover {
      text-decoration: none; }
  .left-nav .nav-header li a {
    font-weight: normal; }
  .left-nav li ul {
    margin-top: -16px; }
  .left-nav li ul ul {
    margin: 0; }

.left-nav > ul > li {
  font-weight: normal;
  line-height: 40px;
  font-size: 18px; }
  .left-nav > ul > li:before {
    display: inline-block;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAQCAYAAAAMJL+VAAAABGdBTUEAALGPC/xhBQAAA3NJREFUOBGVVF1sTEEUPmfu7fXfapeoJSrS8OQnIcTfQ3erFPWmj8ITD9RWW/2xkhvdVVqb3daTeOBRkIiU+OlqJUhEmqDUi65/KrK6trTdvXvvHWfK1Co2cZKbMzPnO9/MmfudQZev4jRH9kLhrGvdoewHOuo2ZLCdZ/SJH/pjSy0LVxBsloDayBUE6NcUvHK9PvRKrEnDIl9FYGJ2YYMRf1kEaK/kyBE49Dhy8zq+WXkpYyiyhFt8OXLmFEk2gxHCPc7K0rpv1pz4JIk2N9fkJ5JGGWXPZwBDoEBHuC7UjVRBS6e3rUYChS8+VrnYMq2dgDiFcbyjInTeOBTqT8dkGpe0VE8xk0YJcFzPOLA/riRcF3xCV9au2HgOkIf/h1xsTJUNKRo85QweMcapqAzGOVMzhP8ZMlO4mqFynyqAPyrgtCltO4czPteRmxPf6PfMHs9Uevzg3E1NnvniGx8Tc2ZjYbg+8FwlIisdIBLdfk8tIr9nA7JoLBZSVNbq8lVpEkdJbNXKyW/1omZT5zozrKqCTm/gtYwLL45IxlWk45adqJrRXh2IikDSSOxxLMqrGuj7so2KG54xSdkXHbFaurzBvSIu7ebPgZD1xqbKLLkuPfHT5QCgu9FzhH5klO7JQYpBGpvA1Iu2leqliY0MnHSGag44LJMkyZinPiC2bEB4RxLt5zZ+IJ4Nnd7WelUQ3vK2tkmw27e/QZtaEEkMRq5SP3wNN4Q+ufwercvb+puUJT7dl53SJxsDcWeK207KHRWPSv+TNv1lCKw38e3FFiLcKlZdDHaQEnp+If49at+tD1O0r7ip0rZNyxRIlX736F3JtLA3eHnDUc9al2//SQ5cYza7dOtw8LqMC7+lqS7XgkSOiZyIqA3JHAtXvbtQXm6VHq2fmUL7M2fJIbH+V413NITuFTd66MchhA8HbwugNCHLJPIYPQOv5JqQtdt/wFniO8BUzRi5Vh2Iuxsrpom4So9LxkaTJMILopLmWiNcezwu5qVtbROm5uebJEch9fdibcwYft5+Xtf+WsEYKG1AfVDg9leuoaIWkPImiJAxGEkMDEZUmv/gQf4RGD5UstQeSKXuRvtim9DVWHGB+q43jWt0KF5FOrNJCnhDzzE1NbwhPTyZ55z+7OwuPTEeL+biRU0mzWWA1hJq0hy6GvYdve55VOIUad0AAAAASUVORK5CYII=); }

.left-nav > ul > li li li {
  font-size: 18px;
  font-weight: normal;
  margin-left: 20px; }

* {
  box-sizing: border-box; }

a,
a:visited {
  color: #1E856D;
  text-decoration: none; }

a:hover {
  color: #283E40; }

.component-dashboard-tv-view {
  position: relative;
  height: 100%;
  width: 100%;
  background-color: #013039;
  color: #ffffff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column; }
  .component-dashboard-tv-view .loading-content {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #00262c;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center; }
  .component-dashboard-tv-view h1 {
    flex: 0 1 auto;
    height: 50px;
    margin: 0;
    padding: 15px 20px 0;
    font-weight: lighter; }
  .component-dashboard-tv-view .tv-content {
    flex: 1 1 auto;
    height: 75%;
    position: relative;
    display: flex;
    padding: 20px; }
  .component-dashboard-tv-view .tv-column {
    flex: 0 1 auto;
    width: 50%;
    display: flex;
    flex-direction: column; }
  .component-dashboard-tv-view .tv-row {
    margin: 10px 0;
    padding: 10px;
    flex: 1 1 auto;
    height: 10%;
    background-color: #00262c;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3); }
    .component-dashboard-tv-view .tv-row.single-value-items {
      flex: 0 0 auto;
      height: 80px;
      background-color: transparent;
      box-shadow: none;
      display: flex;
      flex-direction: row;
      padding: 0; }
      .component-dashboard-tv-view .tv-row.single-value-items .single-value-item {
        flex: 0 1 auto;
        width: 50%;
        height: 100%;
        padding: 10px;
        background-color: #00262c;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
        text-align: center; }
        .component-dashboard-tv-view .tv-row.single-value-items .single-value-item:not(:last-child) {
          margin-right: 20px; }
        .component-dashboard-tv-view .tv-row.single-value-items .single-value-item h2 {
          margin-top: 10px; }
  .component-dashboard-tv-view .mini-map {
    padding: 0;
    height: 40%; }
  .component-dashboard-tv-view .production-graph {
    height: 30%; }

.component-dashboard-tv-view .state-animate-enter {
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  opacity: 0; }

.component-dashboard-tv-view .state-animate-enter-active {
  -webkit-animation-name: fadein;
          animation-name: fadein; }

.component-dashboard-tv-view .state-animate-enter-done {
  opacity: 1; }

.component-dashboard-tv-view .state-animate-exit {
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
  opacity: 1; }

.component-dashboard-tv-view .state-animate-exit-active {
  -webkit-animation-name: fadeout;
          animation-name: fadeout; }

.component-dashboard-tv-view .state-animate-exit-done {
  opacity: 0;
  display: none; }

.animate-pulsate {
  -webkit-animation: pulsate 2s ease-out;
  -webkit-animation-iteration-count: infinite;
  opacity: 0.5; }

@-webkit-keyframes pulsate {
  0% {
    opacity: 0.5; }
  50% {
    opacity: 1.0; }
  100% {
    opacity: 0.5; } }

@-webkit-keyframes fadeout {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes fadeout {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@-webkit-keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

* {
  box-sizing: border-box; }

a,
a:visited {
  color: #1E856D;
  text-decoration: none; }

a:hover {
  color: #283E40; }

.component-turbine-tv-row {
  width: 100%;
  height: 10%;
  flex: 1 1 auto;
  padding: 10px 10px 10px 0;
  display: flex;
  flex-direction: row; }
  .component-turbine-tv-row .turbine-tv-row-item {
    flex: 1 1 auto;
    margin-right: 20px;
    background-color: #00262c;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    border-radius: 4px; }
    .component-turbine-tv-row .turbine-tv-row-item:last-child {
      margin-right: 10px; }
  .component-turbine-tv-row .turbine-overview {
    width: 35%;
    min-width: 200px;
    position: relative;
    overflow: hidden;
    border: solid 2px transparent;
    position: relative; }
    .component-turbine-tv-row .turbine-overview img {
      height: 60%; }
    .component-turbine-tv-row .turbine-overview .maintenance-banner {
      display: none;
      background-color: #cb3f32;
      color: #fff; }
    .component-turbine-tv-row .turbine-overview.needs-maintenance {
      border: solid 2px #cb3f32; }
      .component-turbine-tv-row .turbine-overview.needs-maintenance .maintenance-banner {
        display: block;
        position: absolute;
        font-size: 11px;
        font-weight: bold;
        left: 0;
        right: 0;
        top: 0;
        text-align: center;
        padding: 5px; }
        .component-turbine-tv-row .turbine-overview.needs-maintenance .maintenance-banner span {
          background: url("/warning.png") no-repeat left center;
          padding-left: 18px;
          padding-right: 5px; }
    .component-turbine-tv-row .turbine-overview .title-details {
      position: absolute;
      top: 20px;
      padding: 10px;
      background-color: rgba(0, 0, 0, 0.3); }
      .component-turbine-tv-row .turbine-overview .title-details h2 {
        margin: 0;
        font-size: 22px;
        font-weight: normal;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5); }
      .component-turbine-tv-row .turbine-overview .title-details h3 {
        margin: 0;
        font-size: 13px;
        text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5); }
    .component-turbine-tv-row .turbine-overview .title-data {
      padding: 10px; }
      .component-turbine-tv-row .turbine-overview .title-data h2 {
        margin: 0;
        font-size: 13px;
        color: #5f94e0;
        font-weight: bolder;
        color: #76a97d; }
      .component-turbine-tv-row .turbine-overview .title-data .real-time-value {
        display: block;
        font-size: 28px;
        font-weight: bold;
        padding-bottom: 10px; }
  .component-turbine-tv-row .turbine-chart {
    width: 65%;
    padding-left: 10px; }
    .component-turbine-tv-row .turbine-chart h2 {
      font-size: 13px;
      color: #5f94e0;
      font-weight: bolder; }
    .component-turbine-tv-row .turbine-chart .real-time-value {
      display: block;
      font-size: 28px;
      font-weight: bold;
      padding-bottom: 10px; }

* {
  box-sizing: border-box; }

a,
a:visited {
  color: #1E856D;
  text-decoration: none; }

a:hover {
  color: #283E40; }

.component-turbine-power-chart {
  width: 100%;
  height: 100%; }
  .component-turbine-power-chart h2 {
    font-size: 13px;
    color: #5f94e0;
    font-weight: bolder; }
  .component-turbine-power-chart .real-time-value {
    display: block;
    font-size: 28px;
    font-weight: bold;
    padding-bottom: 10px; }

