@import "../../styles/base.scss";

.detail-view {
	position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;

	.image {
		width: 200px;
		height: 200px;
		margin: 0 15px 15px 0;
		float: left;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: top center;

		.thumbnail {
			width: 100%;
			height: 100%;
		}
	}

	.maintenance-button {
		float: left;
		margin-top: 15px;
		width: 220px;
		background-color: #C83C36;
		border: none;
		border-radius: 19px;
		height: 38px;
		font-size: 16px;
		color: white;

		a {
			display: block;
			color: $color-white;
			text-align: center;
			line-height: 38px;
		}
	}

	.record-fields {
		float: left;
		margin: 0;
		font-size: 16px;
		margin-right: 15px;

		dt {
			float: left;
			clear: left;
			width: 100px;
		}

		dd {
			width: 180px;
			text-align: right;
		}

		.list-link {
			margin-left: 0;
			text-align: left;
		}

		.record-title {
			font-size: 24px;
			font-weight: 300;
			width: 100%;
		}
	}

	.detail-view-left {
		flex: 1 1 50%;
		min-width: 450px;
		overflow-y: scroll;
	}

	.detail-view-right {
		flex: 0 1 300px;
		background-color: $color-off-white;
		border-left: $standard-border;
		display: flex;
		flex-direction: column;
	}

	.detail-view-right-content {
		position: relative;
		flex: 1 1 auto;
	}

	header {
		flex: 0 0 40px;
	}

	.detail-data {
		display: flex;
		padding: 25px;
		border-bottom: $standard-border;

		.detail-data-left {
			flex: 1 1 auto;
			min-width: 200px;
		}
		.detail-data-right {
			flex: 0 1 80%;
			display: flex;
			flex-wrap: wrap;
		}

		.data-group {
			flex: 0 1 50%;
			width: 200px;
			height: 100px;

			label {
				font-size: 12px;
				color: $color-darker-blue;
				font-weight: bold;
			}

			.chart {
				width: 200px;
				height: 75px;
				margin-top: 5px;
				background-color: $color-blue;
				border: 1px solid $color-darker-blue;
				border-radius: 3px;
				color: white;
				text-align: center;
				font-size: 44px;
				padding-top: 8px;
				font-weight: bold;
				text-shadow: rgba(0,0,0,0.2) 1px 1px 4px;

				span {
					font-size: 18px;
				}
			}
		}
	}


	.detail-charts {
		.detail-charts-group {
			display: flex;

			h2 {
				font-size: $font-size-small;
				font-weight: bold;
				margin: 0;
				padding: 0;
			}

			.real-time-value {
				font-size: $font-size-extra-large;
				font-weight: bold;
			}

			.component-live-chart {
				flex: 0 1 auto;
				width: 50%;
				padding: 25px;

				&.theme-green {
					color: #357C27;
				}
				&.theme-orange {
					color: #DBA756;
				}
			}
		}
	}

	.maintenance-history {
		padding: 25px;

		h2 {
			font-size: $font-size-medium;
			margin: 0 0 25px;
			padding: 0;
		}

		// history-preview
		a {
			display: block;
			text-decoration: none;
			width: 80%;
			height: 200px;
			background: url('./history.jpg') no-repeat top center;
			background-size: 100% auto;
		}
	}

}
