
.component-apple-map {
	width: 100%;
	height: 100%;
}

// landmark styles

.landmark {
	width: 250px;
	padding: 0;
	background: rgba(247, 247, 247, 0.85);
	border-radius: 5px;
	box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.29);
	-webkit-transform-origin: 0 10px;
	transform-origin: 0 10px;

	.equipment-image {
		width: 100%;
		border-top-left-radius: 5px;
		border-top-right-radius: 5px;
	}

	.user-image {
		width: 26px;
		height: 26px;
		border-radius: 13px;
		margin-right: 10px;
		object-fit: cover;
	}

	.link a {
		text-align: right;
		color: #0E7DCC;

		&:visited {
			color: #0E7DCC;
		}
	}

	h1 {
		position: relative;
		margin-top: -34px;
		padding: 5px 15px;
		background: rgba(0,0,0,0.3);
		color: rgba(255, 255, 255, 0.9);
		font-size: 16px;
		font-weight: 300;
		overflow: hidden;
		text-overflow: ellipsis;
		whte-space: nowrap
	}

	.lat-lon {
		font-style: italic;
	}
}

.landmark h5 {
	margin-bottom: 0;
	color: rgb(51, 51, 51);
	font-size: 14px;
	font-weight: 500;
}

.landmark section {
	padding: 0 15px 5px;
	font-size: 14px;
}

.landmark section p {
	margin: 5px 0;
}

.landmark:after {
	content: "";
	position: absolute;
	top: 34px;
	left: -13px;
	width: 0;
	height: 0;
	margin-bottom: -13px;
	border-right: 13px solid rgba(247, 247, 247, .85);
	border-top: 13px solid rgba(0, 0, 0, 0);
	border-bottom: 13px solid rgba(0, 0, 0, 0);
}

@-webkit-keyframes scale-and-fadein {
	0% {
		-webkit-transform: scale(0.2);
		opacity: 0;
	}

	100% {
		-webkit-transform: scale(1);
		opacity: 1;
	}
}

@keyframes scale-and-fadein {
	0% {
		transform: scale(0.2);
		opacity: 0;
	}

	100% {
		transform: scale(1);
		opacity: 1;
	}
}
