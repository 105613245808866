
.component-live-chart {
	&.chart-content {
		height: 100%;
		display: flex;
		flex-direction: column;
		h2, span {
			max-height: 25%;
			flex: 0 1 auto;
		}
		.chart-content {
			flex: 1 1 auto;
			max-height: 80%;
			position: relative;
			overflow: hidden;
		}
	}
}
