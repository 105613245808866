@import "../../styles/base.scss";

$box-shadow-config: 5px 5px 10px rgba(0, 0, 0, .3);
$text-shadow-config: 2px 2px 2px rgba(0, 0, 0, .5);

.component-turbine-tv-row {
	width: 100%;
	height: 10%;
	flex: 1 1 auto;
	padding: 10px 10px 10px 0;

	display: flex;
	flex-direction: row;

	.turbine-tv-row-item {
		flex: 1 1 auto;
		margin-right: 20px;
		&:last-child {
			margin-right: 10px;
		}
		background-color: $color-darker-green-bg;
		box-shadow: $box-shadow-config;
		border-radius: 4px;
	}

	.turbine-overview {
		width: 35%;
		min-width: 200px;
		position: relative;
		overflow: hidden;
		border: solid 2px transparent;
		position: relative;

		img {
			height: 60%;
		}

		.maintenance-banner {
			display: none;
			background-color: rgb(203, 63, 50);
			color: #fff;
		}

		&.needs-maintenance {
			border: solid 2px rgb(203, 63, 50);

			.maintenance-banner {
				display: block;
				position: absolute;
				font-size: 11px;
				font-weight: bold;
				left: 0;
				right: 0;
				top: 0;

				text-align: center;
				padding: 5px;

				span {
					background: url('/warning.png') no-repeat left center;
					padding-left: 18px;
					padding-right: 5px;
				}
			}
		}

		.title-details {
			position: absolute;
			top: 20px;
			padding: 10px;
			background-color: rgba(0, 0, 0, .3);
			h2 {
				margin: 0;
				font-size: 22px;
				font-weight: normal;
				text-shadow: $text-shadow-config;
			}
			h3 {
				margin: 0;
				font-size: 13px;
				text-shadow: $text-shadow-config;
			}
		}

		.title-data {
			padding: 10px;
			h2 {
				margin: 0;
				font-size: 13px;
				color: $color-title-blue;
				font-weight: bolder;
				color: rgb(118, 169, 125);
			}
			.real-time-value {
				display: block;
				font-size: 28px;
				font-weight: bold;
				padding-bottom: 10px;
			}
		}
	}

	.turbine-chart {
		width: 65%;
		padding-left: 10px;
		h2 {
			font-size: 13px;
			color: $color-title-blue;
			font-weight: bolder;
		}
		.real-time-value {
			display: block;
			font-size: 28px;
			font-weight: bold;
			padding-bottom: 10px;
		}
	}
}
